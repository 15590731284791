import React from "react";

import styles from "./Hamburger.module.css";

/**
 * Slider actual content
 */
const Hamburger = ({ isOpen, setOpen }) => {
  const navPopupClass = isOpen ? styles.isOpen : "";

  return (
    <div
      className={`${styles.Hamburger} ${navPopupClass}`}
      onClick={() => setOpen(!isOpen)}
      onKeyDown={() => setOpen(!isOpen)}
      tabIndex="0"
      role="button"
      aria-pressed="false"
    >
      <div className={styles.icon}>
        <div className={styles.inner} />
      </div>
    </div>
  );
};

export default Hamburger;
