import PropTypes from "prop-types";
import React from "react";

import styles from "./Header.module.css";

const Header = ({ siteTitle, isOpen }) => {
  const navPopupClass = isOpen ? styles.isOpen : "";

  return (
    <header className={`${styles.Header} ${navPopupClass}`}>
      <h1 className={styles.HeaderContainer}>
        <span className={styles.HeaderItem}>
          Vins Baratta
          <span className={styles.dividerDesktop}>&nbsp;-&nbsp;</span>
        </span>
        <span className={styles.HeaderItem}>
          Fashion Photographer
          <span className={styles.dividerDesktop}>&nbsp;-&nbsp;</span>
        </span>
        <span className={styles.HeaderItem}>Milan based</span>
      </h1>
      <nav className={styles.HeaderNav}>
        <ul>
          <li className={styles.dividerDesktop}>&nbsp;-&nbsp;</li>
          <li className={styles.HeaderNavItem}>
            <a href="mailto:vins.baratta@gmail.com">Contact</a>
          </li>
          <li>&nbsp;-&nbsp;</li>
          <li className={styles.HeaderNavItem}>
            <a href="https://www.instagram.com/vinsbaratta/">Instagram</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
